import * as React from "react"

import "./src/styles/tailwind.css"
import "vanilla-cookieconsent/dist/cookieconsent.css"

import QueryClientProvider from "./src/components/layouts/QueryClientProvider"

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider>{element}</QueryClientProvider>
)
